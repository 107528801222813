import { useEffect, useReducer, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import styles from './styles.module.scss'
import { initialPartState } from './initialPartState'
import { Part, partFields, partInput, partCreateUpdateActionTypes, partSpecialPropsFields, fieldValuePair, IPriceChartResponse, MarketplacesKeys } from '../../../types/part/partTypes'
import conditions from '../../../constants/conditions'
import ModalImageGroup from '../../_atoms/ModalImageGroup/ModalImageGroup'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'
import Loader from '../../_atoms/Loader/Loader'
import { useSelector } from '../../../modules/store/customSelector'
import { fontSizes, fontWeights } from '../../../constants/salesStyles'
import StickersApiService from '../../../modules/api/StickersApiService'
import getDocumentsSelectSearch from '../../../modules/redux/documentList/GetDocumentsSelectSearch'
import { DocumentType } from '../../../types/document/documentTypes'
import { AppDispatch } from '../../../modules/store/customDispatch'
import SalesText from '../../_atoms/SalesText/SalesText'
import { partReducer } from '../../../pages/NewPart/partReducer'
import SalesCard from '../SalesCard/SalesCard'
import PartCreateUpdateTable from '../PartCreateUpdateTable/PartCreateUpdateTable'
import { carFields } from '../../../types/car/carTypes'
import getAllCarMarks from '../../../modules/redux/catalogs/getAllCarMarks'
import getAllCarModels from '../../../modules/redux/catalogs/getAllCarModels'
import getAllCarModifications from '../../../modules/redux/catalogs/getAllCarModifications'
import getModificationYears from '../../../modules/redux/catalogs/getModificationYears'
import { formatDate } from '../../../utils/converters'
import PriceChart from '../PriceChart/PriceChart'
import getPartPriceRecommendedChart from '../../../modules/redux/partList/getPartPriceRecommendedChart'
import getMarketplacesSettings from '../../../modules/redux/sales/getMarketplacesSettings'
import { adminRouteAlias, getAdminNavigationPath } from '../../../router/adminRouteAlias'
import getApplicabilityByPartId from '../../../modules/redux/partList/GetApplicabilityByPartId'
import getPartTypeByTypeId from '../../../modules/redux/partList/GetPartTypeByTypeId'
import { getFrontRearLeftRight } from '../../../utils/getFrontRearLeftRight'
import getIncomingInvoicesByPartsId from '../../../modules/redux/documentList/GetIncomingInvoicesByPartsId'
import getCarsSelectSearchCarTitleBodyTypeVINcode from '../../../modules/redux/carList/getCarsSelectSearchCarTitleBodyTypeVINcode'
import warehousesSelectSearch from '../../../modules/redux/warehouseList/WarehousesSelectSearch'

interface IProps {
    part: Part
    isOpen: boolean
    closeModal: () => void
}

const ModalViewPart = ({ part, isOpen, closeModal }: IProps) => {
    const title = 'Просмотр запчасти'
    const dispatchRedux = useDispatch<AppDispatch>()
    // @ts-ignore для structedClone
    const [state, dispatch] = useReducer(partReducer, structuredClone(initialPartState))
    const [isLoading, setIsLoading] = useState(true)
    const [isFilledState, setIsFilledState] = useState(false)
    const isPartLoading = useSelector(state => state.partList.isLoading.partList)

    const document = useSelector(state => state.documentList.document)

    const warehouses = useSelector(state => state.warehouseList.warehousesSS)
    const applicabilitySelected = useSelector(state => state.partList.applicability.applicabilitySelected)
    const partType = useSelector(state => state.partList.partType)
    const cars = useSelector(state => state.carList.cars)

    const [priceChartResponse, setPriceChartResponse] = useState<IPriceChartResponse | null>(null)
    const getPriceChartRes = async () => {
        const res = await getPartPriceRecommendedChart(part.typeId, part.markId, part.modelId, part.price)
        setPriceChartResponse(res)
    }

    const getField = (fieldToSearch: carFields | partFields) => state.fields.find((field: partInput) => field.field === fieldToSearch)

    const getCarNameFromExistingCar = () => getField(partFields.carId)?.specialProps?.renderValue.replace(',','')
    const getCarNameFromWithoutCar = () => {
        const carModel = getField(carFields.modelId)?.specialProps?.renderValue
        const carMark = getField(carFields.markId)?.specialProps?.renderValue
        const carYear = getField(carFields.year)?.value

        if (carModel && carMark && carYear) {
            return `${carMark} ${carModel} ${carYear}`.replace(',','')
        }
    }

    const getCustomTitle = () => {
        const currentSwitch = getField(partFields.useExistingCar)?.value

        const carField = getField(partFields.carId)
        const car = cars.find(car => car.value === carField.value)

        const carNameFromExistingCar = car?.customTitle ?? getCarNameFromExistingCar()
        const carNameFromWithoutCar = getCarNameFromWithoutCar()
        const partName = getField(partFields.typeId)?.specialProps?.renderValue

        if (carNameFromExistingCar && partName && currentSwitch === 1) {
            return partName + ' ' + carNameFromExistingCar
        } else if (partName && currentSwitch === 2 && carNameFromWithoutCar) {
            return `${partName} ${carNameFromWithoutCar}`.replace(',','')
        } else if (partName && currentSwitch === 0) {
            return `${partName}`
        } else {
            return ''
        }
    }

    const getVisibleAny = (isVisibleAnyFields: fieldValuePair[]) => {
        let isVisible = false
        isVisibleAnyFields.forEach((item: fieldValuePair) => {
            if (getField(item.field).value === item.value) {
                isVisible = true
                return
            }
        })
        return isVisible
    }

    const getVisibleEvery = (isVisibleAllFields: fieldValuePair[][]) => {
        let isVisible = true
        for (const array of isVisibleAllFields) {
            if (!getVisibleAny(array)) isVisible = false
        }
        return isVisible
    }

    const getVisibleFields = () =>
        state.fields.filter((field: partInput) => {
            if (field.specialProps?.isVisibleEvery) {
                return getVisibleEvery(field.specialProps?.isVisibleEvery)
            } else if (field.specialProps?.isVisibleSwitch && field.specialProps?.isVisibleAny) {
                return getVisibleAny(field.specialProps?.isVisibleSwitch) && getVisibleAny(field.specialProps?.isVisibleAny)
            } else if (field.specialProps?.isVisibleSwitch && !field.specialProps?.isVisibleAny) {
                return getVisibleAny(field.specialProps?.isVisibleSwitch)
            } else if (field.specialProps?.isVisibleAny && !field.specialProps?.isVisibleSwitch) {
                return getVisibleAny(field.specialProps?.isVisibleAny)
            } else {
                return true
            }
        })

    const getVisibleColumn = (column: number) => getVisibleFields().filter((field: partInput) => field.specialProps?.column === column)

    const onCloseModal = (e?: React.MouseEvent) => {
        e?.preventDefault()
        closeModal()
    }

    const printPart = () => {
        const url = StickersApiService.getEncodeUrlFromPart(part)
        if (url) window.open(url, '_blank')
    }

    const fillField = (field: partInput, value: number | string, renderValue?: string) => {
        dispatch({
            type: partCreateUpdateActionTypes.updateField,
            value: {
                field: field.field,
                value,
                specialProps: {
                    ...field.specialProps,
                    [partSpecialPropsFields.renderValue]: renderValue,
                }
            }
        })
    }

    const fillInitialState = () => {
        if (part.markId) {
            dispatchRedux(getAllCarMarks(part.markTitle))
            dispatchRedux(getAllCarModels(part.modelTitle, String(part.markId)))
            dispatchRedux(getAllCarModifications(part.modificationTitle, String(part.modelId)))
            dispatchRedux(getModificationYears(String(part.year), String(part.modificationId)))
        }

        if (part.carId) {
            dispatchRedux(getCarsSelectSearchCarTitleBodyTypeVINcode(`${part.markTitle} ${part.modelTitle} ${part.year}`))
        }

        state.fields.map((field: partInput) => {
            switch (field.field) {
                case partFields.useExistingCar:
                    if (part.carId || part.markId) {
                        dispatch({
                            type: partCreateUpdateActionTypes.updateField,
                            value: {
                                field: field.field,
                                value: part.carId ? 1 : 2,
                            }
                        })
                    }
                    break
                case partFields.carId:
                    part.carId && fillField(field, part.carId, `${part.markTitle} ${part.modelTitle} ${part.year}`)
                    break
                case partFields.markId:
                    part.markId && fillField(field, part.markId, part.markTitle)
                    break
                case partFields.modelId:
                    part.modelId && fillField(field, part.modelId, part.modelTitle)
                    break
                case partFields.modificationId:
                    part.modificationId && fillField(field, part.modificationId, part.modificationTitle)
                    break
                case partFields.year:
                    part.year && fillField(field, part.year, String(part.year))
                    break
                case partFields.groupId:
                    part.groupId && fillField(field, part.groupId, part.groupTitle)
                    break
                case partFields.typeId:
                    part.typeId && fillField(field, part.typeId, part.title)
                    break
                case partFields.customTitle:
                    part.customTitle && fillField(field, part.customTitle, part.customTitle)
                    break
                case partFields.Quantity:
                    part.extraData?.Quantity && fillField(field, part.extraData.Quantity, String(part.extraData.Quantity))
                    break
                case partFields.TireType:
                    part.extraData?.TireType && fillField(field, part.extraData.TireType, String(part.extraData.TireType))
                    break
                case partFields.RimType:
                    part.extraData?.RimType && fillField(field, part.extraData.RimType, String(part.extraData.RimType))
                    break
                case partFields.Brand:
                    part.extraData?.Brand && fillField(field, part.extraData.Brand, part.extraData.Brand)
                    break
                case partFields.TireModel:
                    part.extraData?.TireModel && fillField(field, part.extraData.TireModel, part.extraData.TireModel)
                    break
                case partFields.RimDiameter:
                    part.extraData?.RimDiameter && fillField(field, part.extraData.RimDiameter, String(part.extraData.RimDiameter))
                    break
                case partFields.TireSectionWidth:
                    part.extraData?.TireSectionWidth && fillField(field, part.extraData.TireSectionWidth, String(part.extraData.TireSectionWidth))
                    break
                case partFields.TireAspectRatio:
                    part.extraData?.TireAspectRatio && fillField(field, part.extraData.TireAspectRatio, String(part.extraData.TireAspectRatio))
                    break
                case partFields.RimWidth:
                    part.extraData?.RimWidth && fillField(field, part.extraData.RimWidth, String(part.extraData.RimWidth))
                    break
                case partFields.RimOffset:
                    part.extraData?.RimOffset && fillField(field, part.extraData.RimOffset, String(part.extraData.RimOffset))
                    break
                case partFields.RimBolts:
                    part.extraData?.RimBolts && fillField(field, part.extraData.RimBolts, String(part.extraData.RimBolts))
                    break
                case partFields.RimBoltsDiameter:
                    part.extraData?.RimBoltsDiameter && fillField(field, part.extraData.RimBoltsDiameter, String(part.extraData.RimBoltsDiameter))
                    break
                case partFields.RimDIA:
                    part.extraData?.RimDIA && fillField(field, part.extraData.RimDIA, String(part.extraData.RimDIA))
                    break
                case partFields.conditionId:
                    part.conditionId && fillField(field, part.conditionId, conditions.find(condition => condition.id === part.conditionId)?.title || String(part.conditionId))
                    break
                case partFields.PartBrand:
                    part.extraData?.Brand && fillField(field, part.extraData.Brand, part.extraData.Brand)
                    break
                case partFields.ResidualTread:
                    part.extraData?.ResidualTread && fillField(field, part.extraData.ResidualTread, String(part.extraData.ResidualTread))
                    break
                case partFields.partNumber:
                    dispatch({
                        type: partCreateUpdateActionTypes.updateField,
                        value: {
                            field: field.field,
                            value: part.partNumber,
                            specialProps: {
                                ...field.specialProps,
                                [partSpecialPropsFields.renderValue]: part.partNumber,
                                [partSpecialPropsFields.filterId]: part.id,
                            }
                        }
                    })
                    break
                case partFields.description:
                    part.description && fillField(field, part.description, part.description)
                    break
                case partFields.partPricePurchase:
                    part.extraData?.pricePurchase && fillField(field, part.extraData.pricePurchase, String(part.extraData.pricePurchase))
                    break
                case partFields.partPrice:
                    part.price && fillField(field, part.price, String(part.price))
                    break
                case partFields.warehouseTitle:
                    part.extraData?.warehouse?.id && fillField(field, part.extraData.warehouse.id, warehouses.find(w => w.value === part.extraData?.warehouse?.id)?.name || '')
                    break
                case partFields.warehouseRow:
                    part.extraData?.warehouse?.row && fillField(field, part.extraData.warehouse.row, part.extraData.warehouse.row)
                    break
                case partFields.warehouseShelf:
                    part.extraData?.warehouse?.shelf && fillField(field, part.extraData.warehouse.shelf, part.extraData.warehouse.shelf)
                    break
                case partFields.warehousePlace:
                    part.extraData?.warehouse?.place && fillField(field, part.extraData.warehouse.place, part.extraData.warehouse.place)
                    break
                case partFields.vendorCode:
                    dispatch({
                        type: partCreateUpdateActionTypes.updateField,
                        value: {
                            field: field.field,
                            value: part.vendorCode,
                            specialProps: {
                                ...field.specialProps,
                                [partSpecialPropsFields.placeholder]: part.vendorCode,
                                [partSpecialPropsFields.printerOnClick]: printPart,
                            }
                        }
                    })
                    break
                case partFields.IncomingInvoice:
                    document && fillField(field, document?.id, `${document.title} от ${formatDate(document.createdAt)}`)
                    break
                case partFields.marketplacesDest:
                    if (part.extraData?.marketplacesDest) {
                        const marketplacesDest = getField(field.field)
                        part.extraData.marketplacesDest.forEach(element => {
                            const el = marketplacesDest.value.find((item: partInput) => item.field === element)
                            if (el) {
                                el.value = true
                            }
                            const el2 = marketplacesDest.specialProps.checkboxItems.find((item: partInput) => item.field === element)
                            if (el2) {
                                el2.value = true
                            }
                        })
                    } else {
                        const marketplacesDest = getField(field.field)
                        marketplacesDest.value.forEach((item: partInput) => {
                            item.value = true
                        })
                        marketplacesDest.specialProps.checkboxItems.forEach((item: partInput) => {
                            item.value = true
                        })
                    }
                    break
                case partFields.height:
                    part.extraData?.height && fillField(field, part.extraData.height, String(part.extraData.height))
                    break
                case partFields.width:
                    part.extraData?.width && fillField(field, part.extraData.width, String(part.extraData.width))
                    break
                case partFields.length:
                    part.extraData?.length && fillField(field, part.extraData.length, String(part.extraData.length))
                    break
                case partFields.weight:
                    part.extraData?.weight && fillField(field, part.extraData.weight, String(part.extraData.weight))
                    break
                case partFields.VideoUrl:
                    part.extraData?.VideoUrl && fillField(field, part.extraData.VideoUrl, part.extraData.VideoUrl)
                    break
                case partFields.partId:
                    fillField(field, part.id, part.id)
                    break
            }
        })

        if (part.extraData?.additionalPartNumbers?.length) {
            const field = getField(partFields.partNumber)
            part.extraData.additionalPartNumbers.map(partNumber => {
                dispatch({
                    type: partCreateUpdateActionTypes.addField,
                    value: {
                        field: {
                            fieldType: field.fieldType,
                            valueType: field.valueType,
                            value: '',
                            title: 'Номер запчасти',
                            specialProps: {
                                placeholder: 'введите OEM номер',
                                isAdded: true,
                                addedField: partFields.partNumber,
                                maxAddedFields: field.specialProps?.maxAddedFields,
                                renderValue: partNumber,
                                fieldParent: partFields.partNumber,
                                extraHandle: partFields.partNumber,
                            }
                        },
                        after: field.field,
                    }
                })
            })
        }

        setIsFilledState(true)
    }

    useEffect(() => {
        if (isFilledState) {
            const customTitleField = getField(partFields.customTitle)

            if (!customTitleField.value) {
                const newCustomTitle = getCustomTitle()
                fillField(customTitleField, newCustomTitle, newCustomTitle)
            }
        }
    }, [isFilledState])

    // Первоначальная загрузка данных
    useEffect(() => {
        const fetchData = async () => {
            dispatchRedux(getDocumentsSelectSearch(DocumentType.IncomingInvoice))
            await dispatchRedux(getIncomingInvoicesByPartsId([part.id], true))
            await dispatchRedux(getMarketplacesSettings(MarketplacesKeys.Avito))
            await dispatchRedux(getApplicabilityByPartId(part.id))
            if (part.extraData?.warehouse?.id) {
                await dispatchRedux(warehousesSelectSearch(''))
            }
            setIsLoading(false)
        }

        getPriceChartRes()

        fetchData()
    }, [])

    useEffect(() => {
        if (!isLoading) {
            fillInitialState()

            // if (!part.customTitle) {
            //     const customTitleField = getField(partFields.customTitle)
            //     customTitleField && fillField(customTitleField, getCustomTitle(), getCustomTitle())
            // }
        }
    }, [isLoading])

    // Обновить поле с применимостями
    useEffect(() => {
        if (applicabilitySelected.length) {
            const applicabilitySelectedField = getField(partFields.applicabilitySelected)
            dispatch({
                type: partCreateUpdateActionTypes.updateField,
                value: {
                    field: applicabilitySelectedField.field,
                    value: true,
                    specialProps: {
                        ...applicabilitySelectedField.specialProps,
                        [partSpecialPropsFields.applicabilityItems]: applicabilitySelected,
                    }
                }
            })

            const applicabilityField = getField(partFields.applicability)

            // Дополнительный текст у поля 'Применимость'
            let text = ''
            if (!applicabilityField.value) {
                text = `(${applicabilitySelected.length})`
            } else {
                text = ''
            }

            dispatch({
                type: partCreateUpdateActionTypes.updateSpecialProps,
                value: {
                    field: applicabilityField.field,
                    specialProps: {
                        ...applicabilityField.specialProps,
                        [partSpecialPropsFields.additionalTitleText]: text,
                    }
                }
            })
        }
    }, [applicabilitySelected])

    // Получаем partType по typeId
    useEffect(() => {
        const typeId = getField(partFields.typeId).value
        typeId && dispatchRedux(getPartTypeByTypeId(typeId))
    }, [getField(partFields.typeId).value])

    const fillFieldPlaceholder = (partField: partFields, value: number) => {
        const field = getField(partField)
        dispatch({
            type: partCreateUpdateActionTypes.updateSpecialProps,
            value: {
                field: field.field,
                value,
                specialProps: {
                    ...field.specialProps,
                    [partSpecialPropsFields.placeholder]: value,
                    [partSpecialPropsFields.onClick]: () => dispatch({
                        type: partCreateUpdateActionTypes.fillDimensionsAndWeight,
                    }),
                }
            }
        })
    }

    // Заполнение placeholder у размеров, веса и расположения
    useEffect(() => {
        if (partType) {
            if (partType.height) {
                fillFieldPlaceholder(partFields.height, partType.height)
            }
            if (partType.width) {
                fillFieldPlaceholder(partFields.width, partType.width)
            }
            if (partType.length) {
                fillFieldPlaceholder(partFields.length, partType.length)
            }
            if (partType.weight) {
                fillFieldPlaceholder(partFields.weight, partType.weight)
            }
            const positionField = getField(partFields.position)
            const position = getFrontRearLeftRight(partType)
            dispatch({
                type: partCreateUpdateActionTypes.updateField,
                value: {
                    field: positionField.field,
                    specialProps: {
                        ...positionField.specialProps,
                        [partSpecialPropsFields.renderValue]: position
                    }
                }
            })
        }
    }, [partType])

    return (
        <>
            <Modal
                show={isOpen}
                onHide={onCloseModal}
                size='xl'
                fullscreen={true}
            >
                {(isLoading || isPartLoading) && (
                    <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                        <Loader diameterInPx={100} thicknessInPx={10} />
                    </OverlayWithText>
                )}
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.contentWrap}>
                        <a href='#' onClick={onCloseModal}>Назад к списку запчастей</a>
                        <SalesText
                            text={`${title} ${part.title}`}
                            fontSize={fontSizes.xxl}
                            fontWeight={fontWeights.bold}
                            subText={part.carId || part.markId ? part.carPartTitle : ''}
                            subHref={part.carId ? getAdminNavigationPath(adminRouteAlias.parts.location) + `?carId=${part.carId}` : ''}
                        />
                        <div className={'d-flex'}>
                            <SalesCard
                                widthInPixels={getVisibleColumn(2).length === 0 ? 570 : 1140}
                                minHeightInPixels={600}
                                marginTopPixels={24}
                                overflowY={'visible'}
                            >
                                <div className={styles.cardContentWrap}>
                                    <div className={styles.cardColumnWrap}>
                                        <PartCreateUpdateTable
                                            dispatch={dispatch}
                                            fields={getVisibleColumn(1)}
                                        />
                                    </div>
                                    {getVisibleColumn(2).length === 0
                                        ? null
                                        : <div className={styles.cardColumnWrap}>
                                            <PartCreateUpdateTable
                                                dispatch={dispatch}
                                                fields={getVisibleColumn(2)}
                                                top={priceChartResponse && priceChartResponse.rcmd_price !== 0
                                                    ? <div className={styles.modalEditPart__priceChart}>
                                                        <Form.Label
                                                            className={`${styles.modalEditPart__formLabel}`}>Аналитика
                                                            рынка по похожим запчастям</Form.Label>
                                                        <PriceChart bars={priceChartResponse.res} />
                                                    </div>
                                                    : undefined
                                                }
                                                modalImageGroup={part.images.length
                                                    ? <td className={styles.modalImgGroup}>
                                                        <ModalImageGroup
                                                            images={part.images}
                                                            showMainImg={false}
                                                        />
                                                    </td>
                                                    : undefined
                                                }
                                            />
                                        </div>
                                    }
                                </div>
                            </SalesCard>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalViewPart
